var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"exhibitionComponent"},[_c('div',{staticClass:"thumbnails"},[_c('SwiperWrapper',{ref:"thumb",attrs:{"slideStyle":{
        width: '100%',
      },"swiperData":_vm.data,"defaultSwiperOptions":{
        direction: 'vertical',
        autoplay: false,
        pagination: false,
        spaceBetween: 10,
        slidesPerView: 6,
        navigation: false,
        watchSlidesVisibility: true,
      }},on:{"swiperClickHandler":_vm.swiperClickHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
      var ref_scoped = ref.scoped;
      var small = ref_scoped.small;
      var indx = ref_scoped.indx;
return [_c('el-image',{key:indx,class:[_vm.curIndex == indx && 'active'],attrs:{"src":small,"fit":"contain"}})]}}])})],1),_c('div',{staticClass:"origin-pic"},[(_vm.thumbSwiper)?[(_vm.data.length > 1)?[_c('SwiperWrapper',{attrs:{"slideStyle":{
            width: '100%',
            height: '100%',
          },"swiperData":_vm.data,"defaultSwiperOptions":{
            autoplay: false,
            pagination: false,
            navigation: false,
            thumbs: {
              swiper: _vm.thumbSwiper,
            },
          }},on:{"slideChangeHandler":_vm.slideChangeHandler},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var ref_scoped = ref.scoped;
          var mid = ref_scoped.mid;
          var indx = ref_scoped.indx;
return [_c('el-image',{key:indx,attrs:{"src":mid,"fit":"contain"}})]}}],null,false,2658274278)})]:(_vm.data[0])?[_c('el-image',{attrs:{"src":_vm.data[0].mid,"fit":"contain"}})]:_vm._e()]:_vm._e()],2)])}
var staticRenderFns = []

export { render, staticRenderFns }