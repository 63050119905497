var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"prototypeInfoComponent"},[_c('div',{staticClass:"top-info mb30"},[_c('div',{staticClass:"name-wrapper"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.data.name))])])]),_c('priceTable',{staticClass:"mb30",attrs:{"priceData":_vm.allSizesData[_vm.size] || []}}),_c('div',{staticClass:"mb20 flex-middle"},[_c('span',{staticClass:"label"},[_vm._v("参数")]),_c('div',{staticClass:"el-row flex-one"},_vm._l((_vm.paramsList),function(item){return _c('div',{key:item.prop,staticClass:"el-col-6 text-left"},[_c('div',{staticClass:"inline-block text-center"},[_c('div',{staticClass:"mb10"},[_vm._v(_vm._s(_vm.curSizeData[item.prop] ? _vm.curSizeData[item.prop] + (item.unit || '') : '暂无'))]),_c('div',{staticClass:"text-grey"},[_vm._v(_vm._s(item.label))])])])}),0)]),_c('div',{staticClass:"mb20"},[_c('span',{staticClass:"label"},[_vm._v("颜色")]),_c('el-radio-group',{attrs:{"size":"mini"},model:{value:(_vm.color),callback:function ($$v) {_vm.color=$$v},expression:"color"}},_vm._l((_vm.primStructs),function(ref,index){
var prim_color = ref.prim_color; if ( prim_color === void 0 ) prim_color = {};
var color_value = ref.color_value;
var id = ref.id;
return _c('el-radio',{key:index,staticClass:"color-radio",style:({
          'margin-left': 0,
          backgroundColor: color_value
        }),attrs:{"border":"","label":id}},[_c('span',{style:({ backgroundColor: prim_color.color_value })})])}),1)],1),_c('div',[_c('span',{staticClass:"label"},[_vm._v("尺码")]),_c('el-radio-group',{attrs:{"size":"mini"},model:{value:(_vm.size),callback:function ($$v) {_vm.size=$$v},expression:"size"}},_vm._l((_vm.sizes),function(ref,index){
        var sizeName = ref.sizeName;
        var id = ref.id;
return _c('el-radio',{key:index,staticClass:"size-radio",attrs:{"border":"","label":id}},[_c('span',{staticClass:"size-name"},[_vm._v(_vm._s(sizeName))])])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }