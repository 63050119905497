
<template>
  <section class="app-container app-flex-col-container basics-edit">
    <div class="content">
      <avue-form
        ref="topForm"
        v-model="form.topForm"
        :option="formOption"
        :disabled="isShelves"
        class="search-form-part el-form-required-position"
      >
        <template #productCategoryId="{ prop, column }">
          <ProtoTypeTreeSelect
            size="mini"
            class="w100"
            style="margin-top: 4px"
            placeholder="请选择原型分类"
            :selectedValue.sync="form.topForm[prop]"
            :maxHeight="150"
            :disabled="column.disabled"
            @change="validateField(prop, $event)"
          />
        </template>
      </avue-form>

      <listStateGroup v-model="status" :list="newTabs" class="mb20"></listStateGroup>

      <div class="form-wrapper">
        <component
          v-show="status === item.value"
          :ref="item.value"
          v-for="item in tabs"
          :key="item.value"
          :is="item.value"
          v-model="form"
          :readonly="isShelves"
          :isShelves="isShelves"
        ></component>
      </div>
    </div>

    <div class="bottom">
      <basicsEditPreview :productData="productData || {}" :data="form"></basicsEditPreview>
      <el-button type="primary" @click="doSubmit" :loading="loading"> 保存 </el-button>
      <el-button @click="$router.go(-1)"> 返回 </el-button>
    </div>
  </section>
</template>

<script>
import { formOption, tabs } from './const'
import listStateGroup from '@/views/components/listStateGroup'
import ProtoTypeTreeSelect from '@/components/protoTypeTreeSelect'
import productDetails from './module/productDetails'
import productSPEC from './module/productSPEC'
import packSPEC from './module/packSPEC'
import clearanceOfGoodsInfo from './module/clearanceOfGoodsInfo'
import basicsEditPreview from './module/basicsEditPreview'
import { mapState } from 'vuex'

import { formOption as productDetailsOption } from './module/productDetails/const'
import { deepClone, getReorderDiffData } from '@/components/avue/utils/util'
import { CUSTOM, SHELVES } from '@/utils/constant'
import { createProto, updateProto } from '@/api/product/protoApi'
import { getValueFromObj } from '@/utils'
import { validatenull } from '@/components/avue/utils/validate'

export default {
  name: 'basicsEdit',
  components: {
    listStateGroup,
    ProtoTypeTreeSelect,
    productDetails,
    productSPEC,
    packSPEC,
    clearanceOfGoodsInfo,
    basicsEditPreview
  },
  data() {
    this.$store.dispatch('HandleOption', formOption)
    return {
      formOption,
      tabs,
      form: {
        topForm: {}
      },
      errInfo: {
        prim_id: null,
        errFunc: []
      },
      status: 'productDetails',
      loading: false
    }
  },
  computed: {
    ...mapState({
      productData(state) {
        let productData = state.bus.basicsEdit
        if (productData) {
          productData.deliveryTimeMin = productData.deliveryTimeMin > 0 ? productData.deliveryTimeMin : ''
          productData.deliveryTimeMax = productData.deliveryTimeMax > 0 ? productData.deliveryTimeMax : ''
          productData.$sizeList?.forEach((size, index) => (size.sort = index))
          productData.styleList?.forEach((item) => {
            item?.styleDisplayImageList?.forEach((img) => {
              this.$set(img, 'path', img.displayImagePath)
            })
          })
        }
        return productData
      }
    }),
    formProps({ getPropOfOption }) {
      return {
        topForm: getPropOfOption(formOption),
        productDetails: getPropOfOption(productDetailsOption),
        sizeForms: ['size_name', 'weight', 'length', 'width', 'height']
      }
    },
    handleData({ formProps }) {
      let formNames = ['topForm', 'productDetails']
      return (data) => {
        formNames.forEach((name) => {
          this.$set(
            this.form,
            name,
            formProps[name].reduce((prev, next) => {
              prev[next] = data[next]
              return prev
            }, {})
          )
        })
      }
    },
    isShelves({
      $route: {
        query: { status }
      }
    }) {
      return Number(status) === SHELVES
    },
    newTabs() {
      if(this.$route.query.editText) {
        let newTabs = deepClone(this.tabs)
        newTabs.map((item, index) => {
          if(index !== 0) {
            item.disabled = true
          }
        })
        return newTabs
      } else {
        return this.tabs
      }
    }
  },
  watch: {
    productData: {
      handler(n) {
        if (n) {
          let newData = deepClone(n)
          console.log('productData', newData)
          let oProductData = deepClone(n)
          oProductData.sizeList = oProductData.$sizeList
          this.form.productData = oProductData
          this.handleData(newData)

          this.$set(this.form, 'sizeForms', newData.$sizeList)
          this.$set(this.form, 'structForms', newData.styleList)
          this.$set(this.form, 'clearanceOfGoodsInfo', newData.productPrototypeDeclarationInfo)
        } else if (this.$route.query.type === 'edit') {
          this.$router.back()
        }
      },
      immediate: true
    }
  },
  destroyed() {
    this.$store.commit('SET_BUS', {
      basicsEdit: undefined
    })
  },
  methods: {
    async doSubmit() {
      console.log(this.form)
      let valid = await this.validate()
      // console.log(this.$refs.formTab)
      console.log('doSubmit this.form', this.form)
      if (valid) await this.doFunc()
    },
    async doFunc() {
      this.loading = true
      let valid
      if (this.$route.query.type === 'add') {
        valid = await this.doAdd()
      } else {
        valid = await this.doEdit()
      }
      if (valid) {
        this.success()
      }
      this.loading = false
      return valid
    },
    async doAdd() {
      let postData = await this.getPostData()

      console.log(postData)
      let res = await awaitResolve(
        createProto({
          ...postData,
          sizeCreateList: postData.sizeForms,
          sizeForms: undefined,
          styleCreateList: postData.structForms,
          structForms: undefined,
          productPrototypeSizeAttributeCreate: {
            introduceDetail: postData.introduceDetail
          },
          introduceDetail: undefined,
          productPrototypeDeclarationInfoCreate: {
            ...postData.clearanceOfGoodsInfo,
            declaration: undefined
          },
          clearanceOfGoodsInfo: undefined,
          isTort: postData.isTort
        })
      )
      if (res) {
        this.success()
      }
    },
    async doEdit() {
      let { productData } = this
      let postData = await this.getPostData()
      let res = await awaitResolve(
        updateProto({
          ...postData,
          sizeList: getReorderDiffData(postData.sizeForms, productData.$sizeList),
          sizeForms: undefined,
          styleList: postData.structForms,
          structForms: undefined,
          productPrototypeSizeAttributeUpdate: {
            id: getValueFromObj(productData, 'sizeAttributeList[0].id') || undefined,
            introduceDetail: postData.introduceDetail
          },
          introduceDetail: undefined,
          productPrototypeDeclarationInfoUpdate: {
            ...postData.clearanceOfGoodsInfo,
            declaration: undefined
          },
          clearanceOfGoodsInfo: undefined,
          id: productData.id,
          isTort: postData.isTort
        })
      )
      if (res) {
        this.success()
      }
    },
    async getPostData() {
      let { form } = this
      let tempObj = {
        introduceDetail: await this.$refs.packSPEC[0].getParams(),
        clearanceOfGoodsInfo: this.$refs.clearanceOfGoodsInfo[0].postData
      }
      let productSPEC = await this.$refs.productSPEC[0].getParams()
      Object.assign(tempObj, form.topForm, form.productDetails, productSPEC)

      tempObj.deliveryTimeMin = validatenull(tempObj.deliveryTimeMin) ? 0 : tempObj.deliveryTimeMin
      tempObj.deliveryTimeMax = validatenull(tempObj.deliveryTimeMax) ? 0 : tempObj.deliveryTimeMax
      return tempObj
    },
    isCustomId(id) {
      return id.toString().indexOf(CUSTOM) >= 0
    },
    async validate() {
      let { $refs, tabs } = this
      let [, valid] = await awaitWrap($refs.topForm.validate())
      if (!valid) {
        this.$message.warning('请先填写所有必填信息')
      }

      let p = tabs.map(({ value }) => $refs[value][0].validate())
      let valids = await Promise.all(p)
      let errIndex = valids.findIndex((valid) => !valid)
      console.log(valids)
      if (errIndex > -1) {
        this.status = tabs[errIndex].value
        valids[errIndex] !== 0 && this.$message.warning('请完善' + tabs[errIndex].label)
      }
      return valid && errIndex < 0
    },
    success() {
      this.$message.success('操作成功')
      setTimeout(() => this.$router.back(), 500)
    },
    async handleAddRequest(arr) {
      let errFunc = []
      let valids = await Promise.all(arr.map((fn) => fn()))
      valids.forEach((valid, index) => {
        if (!valid) {
          errFunc.push(arr[index])
          if (index === 0) {
            this.status = 'packSPEC'
            this.$message.error('原型尺码表创建失败,请修改正确后保存')
          } else if (index === 1) {
            this.status = 'clearanceOfGoodsInfo'
            this.$message.error('报关信息创建失败,请修改正确后保存')
          }
        }
      })
      return errFunc
    },

    getPropOfOption(option) {
      return option.column.reduce((prev, { prop, minProp, maxProp }) => {
        prev.push(prop)
        if (minProp || maxProp) {
          prev.push(minProp)
          prev.push(maxProp)
        }
        return prev
      }, [])
    },
    validateField(prop, val) {
      this.$nextTick(function () {
        this.$refs.topForm?.[val ? 'clearValidate' : 'validateField'](prop)
      })
    }
  }
}
</script>

<style lang="scss">
.basics-edit {
  .content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 0 20px;
    overflow: auto;
  }
  .form-wrapper {
    flex: 1;
    margin-top: 30px;
    overflow: hidden auto;
  }
  .bottom {
    flex: 0 0 60px;
  }
  .el-radio-button__inner {
    color: #1a1a1a;
  }
}

.basics-edit-component {
  padding: 0 50px;
  .form-item {
    & + .form-item {
      margin-top: 50px;
    }
  }
  .form-label {
    margin: 0 0 20px 10px;
    color: #595961;
  }
}
.is-custom-required {
  position: relative;
  &::before {
    content: '*';
    position: absolute;
    left: -10px;
    color: #f5222d;
  }
}

.add-button.el-button {
  height: 30px;
  line-height: 30px;
  padding: 0 15px;
  background: #f7f8fa;
  border: 1px solid #dcdee0;
  border-radius: 2px;
  font-size: 14px;
  color: $color-primary;
  &:hover {
    background: transparent;
    border-color: $color-primary;
  }
  [class^='el-icon-'] {
    font-size: 10px;
    transform: scale(0.8);
  }
}
</style>
