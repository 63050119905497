<template>
  <div class="basics-edit-component">
    <div class="form-item">
      <div class="form-label">报关信息</div>
      <div class="form-content">
        <avue-form ref="form" v-model="form" :option="formOption" :disabled="isShelves" ></avue-form>
      </div>
    </div>
  </div>
</template>

<script>
import { formOption } from './const'
import { getDeclaredInfo, createDeclaredInfo, editDeclaredInfo } from '@/api/product/productApi'
import { mapState } from 'vuex'
import { getDiffData } from '@/components/avue/utils/util'
import { delPropOfObj } from '@/utils'
import { SHELVES } from '@/utils/constant'

export default {
  props: {
    value: {
      type: Object
    },
    isShelves: {
      type: Boolean,
      default: false
    }
  },
  data() {
    this.$store.dispatch('HandleOption', formOption)
    return {
      formOption,
      form: {}
    }
  },
  computed: {
    ...mapState({
      declaration(state) {
        return state.dic.declaration.dicData.map(dicItem => dicItem.value)
      }
    }),
    postData({ form, declaration }) {
      let tempObj = {
        declaration: undefined
      }
      declaration.forEach(item => {
        if (form.declaration.includes(item)) {
          tempObj[item] = 1
        } else {
          tempObj[item] = 0
        }
      })

      let purpose = form.purpose || null
      return {
        ...form,
        purpose,
        ...tempObj
      }
    }
  },
  watch: {
    'value.clearanceOfGoodsInfo': {
      handler(n) {
        if (!n) {
          this.value.clearanceOfGoodsInfo = n = {}
        }
        let declaration = n.declaration
        if (Array.isArray(declaration)) {
          this.declaration.forEach(item => {
            let value = n[item]
            delete n[item]
            if (Number(value) === 1 && !declaration.includes(item)) {
              declaration.push(item)
            }
          })
        }
        this.form = n
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    async validate() {
      let [, valid] = await awaitWrap(this.$refs.form.validate())
      return valid
    },
    async doSubmit() {
      let valid = await this.validate()
      if (valid) return await this.doFunc()
    },
    async doFunc() {
      let { form: { id }, value: { productData: { id: proId } = {} } } = this
      if (id) {
        return await this.doEdit(id)
      } else {
        return await this.doAdd(proId)
      }
    },
    async doAdd(prim_prod) {
      // prim_prod为原型id
      console.log(prim_prod)
      if (prim_prod) {
        let [err, res] = await awaitWrap(
          createDeclaredInfo({
            ...this.postData,
            prim_prod
          })
        )
        if ($SUC(res)) {
          return true
        }
      }
    },
    async doEdit(id) {
      if (this.isShelves) return true
      let diffData = getDiffData(this.postData, this.oForm) || {}
      delPropOfObj(diffData, ['id', 'declaration'])
      // console.log(diffData, this.postData, this.oForm)
      if (Object.keys(diffData).length) {
        let [err, res] = await awaitWrap(
          editDeclaredInfo(id, diffData)
        )
        if ($SUC(res)) {
          return res.detail
        }
      } else {
        return true
      }
    }
  }
}
</script>

<style lang="scss"></style>
