var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"recommendComponent"},[_c('Swiper',{attrs:{"slideStyle":{
      width: ("calc(" + ((1 / _vm.picSize) * 100) + "% - 30px)"),
      height: '100%',
    },"swiperData":_vm.data,"defaultSwiperOptions":{
      autoplay: false,
      pagination: false,
      slidesPerView: 'auto',
    }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
    var scoped = ref.scoped;
    var indx = ref.indx;
return [_vm._t("default",null,{"scoped":{ scoped: scoped, indx: indx }})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }